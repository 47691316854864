// index.js
import React, { useState } from "react";
import Typography from "../../../components/Typography";
import ScheduleForm from "../../FormsThird/Consultation-Classic";
import { Container, Content, FormContainer, FormHeading, IconsWrapper, IconItem, ImageBackground } from "./styled";
import authority from "../EEAT/_authority.json";
import Image from "next/image";
import ModalContactSuccess from "../../../containers/Modals/ModalContactSuccess";

export default function GetStarted({ formId }) {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  
  const handleFormSuccess = () => {
    setShowSuccessModal(true);
  };
  
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <Container>
      <ImageBackground />
      <Content>
        <div>
          <FormContainer>
            <FormHeading>
              Complimentary Design Consultation & Estimate
            </FormHeading>
            <ScheduleForm 
              formId={formId} 
              submitText="GET STARTED" 
              variant="small" 
              darkLabels={false}
              onFormSuccess={handleFormSuccess}
            />
            <IconsWrapper>
              {authority.map((item) => (
                <IconItem key={item.id}>
                  <Image
                    src={item.icon}
                    alt={item.title}
                    width={40}
                    height={40}
                    quality={70}
                  />
                  <span className="icon-title">{item.title}</span>
                </IconItem>
              ))}
            </IconsWrapper>
          </FormContainer>
        </div>
      </Content>
      
      <ModalContactSuccess 
        open={showSuccessModal}
        onRequestClose={handleCloseSuccessModal}
      />
    </Container>
  );
}
