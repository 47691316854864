// styled.js
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 600px;
  background-image: url('/images/home_page/heers-23.jpg');
  background-size: cover;
  background-position: center;
  padding: 60px 0;

  @media only screen and (max-width: 768px) {
    min-height: auto;
    padding: 0;
    background: none;
    display: flex;
    flex-direction: column;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.0);

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const ImageBackground = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
    height: 300px;
    background-image: url('/images/home_page/heers-23.jpg');
    background-size: cover;
    background-position: center;
  }
`;

export const Content = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding: 0 40px;

  > div {
    width: auto;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
    width: 100%;

    > div {
      width: 100%;
    }
  }

  .title-info {
    margin-top: 10px;
    text-align: center;
    color: white;
    
    a {
      color: white !important;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  max-width: 480px;
  background: rgba(118, 39, 56, 1);
  padding: 40px;
  border-radius: 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding: 30px 20px;
    border-radius: 0;
  }
`;

export const FormHeading = styled.h3`
  font-family: "Lora Bold", serif;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 10px;
  color: #ffffff;
  line-height: 1.4;

  @media only screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const IconsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
  margin: 0;
  margin-top: 40px;
  
  @media only screen and (max-width: 768px) {
    gap: 10px;
    margin-top: 30px;
  }
`;

export const IconItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-bottom: 8px;
    filter: brightness(0) invert(1);

    @media only screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
      margin-bottom: 4px;
    }
  }

  .icon-title {
    font-size: 0.8rem;
    font-family: "Roboto Bold";
    color: white;
    text-shadow: none;
    white-space: pre-wrap;
    line-height: 0.9;

    @media only screen and (max-width: 768px) {
      font-size: 0.7rem;
      line-height: 1;
    }
  }
`;
